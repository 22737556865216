

















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import $ from 'jquery';
// banner组件通信传值设定：
// initOptions：swiper初始化相关参数
// page：getHeaderBanner的传参
// initSwiper：是否为轮播，默认非轮播
// data：自定义banner数据
@Component
export default class InsCmsTwoSwiper extends Vue {
  @Prop() private initOptions!: object;
  @Prop({ default: false }) private initSwiper!: boolean;
  @Prop() private data!: any;
  page: number = 1;
  pageSize: number = 100;
  swiperData: object = {};
  // currentIndex: number = 0;

  TopOption: object = {
    // autoplay: true,
    spaceBetween: 10,
    slidesPerView: this.yearPerView,
    navigation: {
      prevEl: '.year-button-prev',
      nextEl: '.year-button-next'
    },
    autoHeight: true, // 高度随内容变化
    observer: true, // 修改swiper自己或子元素时，自动初始化swiper
    observeParents: true // 修改swiper的父元素时，自动初始化swiper
  }
  GalleryOption: object = {
    // autoplay: true,
    grabCursor: true,
    spaceBetween: 30,
    slidesPerView: this.galleryPerView,
    centeredSlides: true,
    slideToClickedSlide: true,
    navigation: {
      prevEl: '.gallery-button-prev',
      nextEl: '.gallery-button-next'
    },
    autoHeight: true, // 高度随内容变化
    observer: true, // 修改swiper自己或子元素时，自动初始化swiper
    observeParents: true // 修改swiper的父元素时，自动初始化swiper
  }

  // 获取 Category 和所有 SubCategory 的 cms 列表
  getCourseContents () {
    this.$Api.cms
      .getSubCatContents({ CatId: this.id, Page: this.page, PageSize: this.pageSize, IsMobile: this.isMobile })
      .then(result => {
        if (result.Data && result.Data.length) {
          result.Data.forEach(item => {
            this.$set(item, 'ShowMore', false);
          });
          this.swiperData = result.Data.reverse();
          console.log(this.swiperData, '歷程數據');
        }
      });
  }

  clickYear (i) {
    this.gallerySwiper.slideTo(i);
    this.yearSwiper.activeIndex = i;
  }

  slideChangeYear () {
    this.gallerySwiper.slideTo(this.yearSwiper.activeIndex);
    this.gallerySwiper.activeIndex = this.yearSwiper.activeIndex;
  }
  slideChangeGallery () {
    this.yearSwiper.slideTo(this.gallerySwiper.activeIndex);
    this.yearSwiper.activeIndex = this.gallerySwiper.activeIndex;
  }
  get gallerySwiper () {
    return (this as any).$refs.gallerySwiper.swiper;
  }
  get yearSwiper () {
    return (this as any).$refs.yearSwiper.swiper;
  }
  get yearPerView () {
    return this.isMobile ? 5 : 17;
  }
  get galleryPerView () {
    return this.isMobile ? 1 : 3;
  }
  get id () {
    console.log(this.$route.params.id, '当前id');
    return this.$route.params.id;
  }
  get isMobile () {
    return this.$store.state.isMobile;
  }
  stopLoop () {
    this.yearSwiper.autoplay.stop();
    this.gallerySwiper.autoplay.stop();
  }

  mounted () {
    this.getCourseContents();
  }
  @Watch('isMobile', { deep: true })
  onMediaChange () {
    this.swiperData = {};
    this.getCourseContents();
  }
}
